import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { BASE_URL_ENV } from "../SnapshotFacilityTasks/constants";
import styles from "./styles.module.css";
import axios from "axios";
import { getFacilityTitle, useSnapShotUser } from "../../../utilities/helper";
import moment from "moment";
import { BreadCrumb } from "../../../components";
import { PATH } from "../../../routers";
import { BsArchive } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const TABLE_FIELDS = [
  {
    header: "Facility",
    key: "facility_name",
  },
  {
    header: "Maintenance Tech",
    key: "primary_field_staff",
  },
  {
    header: "Last Full WalkThrough",
    key: "last_full_walkthrough_at",
  },
  {
    header: "Last Brand Standards",
    key: "last_brand_standards_at",
  },
  {
    header: "Tasks Past Due",
    key: "tasks_past_due_count",
  },

  {
    header: "Open Tasks",
    key: "open_tasks_count",
  },
];

function SnapshotDashboard(props) {
  const [tasks, setTasks] = useState([]);
  const [unfilteredTasks, setUnfilteredTasks] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [currentSort, setCurrentSort] = useState({ facility_name: "sort-up" });
  const [search, setSearch] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const { user } = useSnapShotUser();

  const [dashboardUrl, setDashboardUrl] = useState(null);
  const portfolioId = user.portfolio?.id;

  const EMBED_URL_ENDPOINT = "https://nb4cmq1q7h.execute-api.us-east-1.amazonaws.com/default/quicksight-embed-url-for-snapshot";

  useEffect(() => {
    // useEffect hook to fetch data when the component mounts
    const timeout = setTimeout(() => {
      fetch(
        [EMBED_URL_ENDPOINT] // Replace [YOUR_API_ENDPOINT] with the actual API endpoint URL
      )
        .then(
          (response) => response.json() // Parsing the response to JSON
        )
        .then((response) => {
          setDashboardUrl(response.EmbedUrl + "#p.portfolioid=" + portfolioId); // Setting the dashboard URL state with the embed URL from the response
        });
    }, 10); // Fetching the data after a timeout of 10 milliseconds
    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, []);

  const getSiteNumber = (facilityName) => {
    return parseInt(facilityName.split(" ").pop());
  };

  const fetchSnapshotDashboard = async () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "X-User-Email": user.email,
          "X-User-Token": user.user_token,
        },
      };
      const response = await axios.get(`${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/facilities/overview`, config);

      const activeLocations = response.data.filter((e) => !e.hide_in_snapshot);
      const results = activeLocations.sort((t1, t2) => {
        const siteNum1 = getSiteNumber(t1.facility_name);
        const siteNum2 = getSiteNumber(t2.facility_name);
        return isNaN(siteNum1 - siteNum2) ? 0 : siteNum1 - siteNum2;
      });
      setTasks(results);
      setUnfilteredTasks(results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsLoaded(true);
    }
  };

  const filterSearchResult = async (unfilteredTasks, search) => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          "X-User-Email": user.email,
          "X-User-Token": user.user_token,
        },
      };
      const result = await axios.get(
        `${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/tasks/search?query=${search}`,
        config
      );
      const filterTasks = unfilteredTasks
        .filter((task) => result.data.site_codes.includes(task.site_code))
        .sort((t1, t2) => {
          const siteNum1 = getSiteNumber(t1.facility_name);
          const siteNum2 = getSiteNumber(t2.facility_name);
          return isNaN(siteNum1 - siteNum2) ? 0 : siteNum1 - siteNum2;
        });
      setTasks(filterTasks);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFilterSearchResult = useCallback(
    debounce((unfilteredTasks, search) => {
      if (search) {
        filterSearchResult(unfilteredTasks, search);
      } else {
        fetchSnapshotTasksOverview();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    fetchSnapshotDashboard();
  }, []);

  useEffect(() => {
    if (!isLoaded) return;
    debouncedFilterSearchResult(unfilteredTasks, search);
  }, [search]);

  const toggleSort = (key) => {
    let currSort = currentSort[key] || "sort";
    if (currSort === "sort") {
      currSort = "sort-up";
    } else if (currSort === "sort-up") {
      currSort = "sort-down";
    } else if (currSort === "sort-down") {
      currSort = "sort-up";
    }
    sortTasks(key, currSort);
    setCurrentSort({ [key]: currSort });
  };

  const sortTasks = (key, sortDirection) => {
    const newTasks = [...tasks];
    newTasks.sort((t1, t2) => {
      const sortD = sortDirection === "sort-up" ? 1 : -1;

      if (key === "facility_name") {
        const siteNum1 = getSiteNumber(t1.facility_name);
        const siteNum2 = getSiteNumber(t2.facility_name);
        const sortIndex = (siteNum1 - siteNum2) * sortD;

        return isNaN(sortIndex) ? t1.facility_name.localeCompare(t2.facility_name) * sortD : sortIndex;
      }
      if (key === "primary_field_staff") {
        return t1.primary_field_staff?.full_name || "" > t2.primary_field_staff?.full_name || "" ? sortD : -sortD;
      }
      if (key === "last_full_walkthrough_at" || key === "last_brand_standards_at") {
        return (moment(t1[key] || 0).unix() - moment(t2[key] || 0).unix()) * sortD;
      }

      return (parseInt(t1[key]) - parseInt(t2[key])) * sortD;
    });
    setTasks(newTasks);
  };

  return (
    <div>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <SnapShotHeader />
      <div>
        <div className="row justify-content-between pt-2">
          <div className="col-9 ml-2">
            <BreadCrumb title="Facility Tasks" path={PATH.CSR_MAIN} />
          </div>
        </div>
      </div>
      <div className="col-12 bg-light min-vh-100">
        <div className="col-11 mx-auto py-3">
          <div className="d-flex align-items-center justify-content-between">
            <p className={styles.topHeader}>Dashboard</p>
          </div>
          <div className="quicksight-embeded-iframe">
            <iframe width="100%" height="720" src={dashboardUrl}>
              {" "}
              {/* Embedding the QuickSight dashboard using the iframe */}
            </iframe>
          </div>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                {TABLE_FIELDS.map((field) => (
                  <th
                    key={field.key}
                    scope="col"
                    onClick={() => toggleSort(field.key)}
                    style={{ cursor: "pointer", userSelect: "none" }}
                  >
                    {field.header} &nbsp;
                    <i className={`fa-solid fa-${currentSort[field.key] || "sort"}`}></i>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <div className="text-center">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null}
              {!isLoading && tasks.length !== 0
                ? tasks.map((task) => (
                    <tr key={task.site_code}>
                      <th scope="row">
                        <Link to={`/snapshot/facility-tasks/${task.site_code}`}>
                          {getFacilityTitle(task.facility_name, task.facility_store_number)}
                        </Link>
                      </th>
                      <td className="text-right">{task.primary_field_staff?.full_name}</td>
                      <td className="text-right">
                        {task.last_full_walkthrough_at
                          ? moment(task.last_full_walkthrough_at).format("MM/DD/YYYY LT")
                          : ""}
                      </td>
                      <td className="text-right flex items-center">
                        {task.last_brand_standards_at ? (
                          <>
                            <Link
                              className="mr-4"
                              to={`/snapshot/${task.site_code}/brands-report/${task.last_brands_report?.id}`}
                            >
                              {moment(task.last_brand_standards_at).format("MM/DD/YYYY LT")}
                            </Link>
                            <Tippy content="Archive">
                              <Link to={`/snapshot/${task.site_code}/brands-report/archive`}>
                                <BsArchive size={18} />
                              </Link>
                            </Tippy>
                          </>
                        ) : null}
                      </td>
                      <td className="text-left">{task.tasks_past_due_count || 0}</td>
                      <td className="text-left">{task.open_tasks_count || 0}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SnapshotDashboard;
