import Pagination from "rc-pagination";
import React from "react";
import { Helmet } from "react-helmet";
import "../../../../src/styles/pagination.css";
import { BreadCrumb } from "../../../components";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { PATH } from "../../../routers";
import { getLocationList, locale } from "../../../utilities/common";
import { titleComponents } from "../../SnapShotDetail/constants";
import { Task } from "../SnapshotFacilityTasks";
import { PAGE_SIZE, TASK_CATEGORIES } from "../SnapshotFacilityTasks/constants";
import { useFetchSnapshotFacilityTasks } from "../SnapshotFacilityTasks/hook";
import "./styles.css";
import { FaSearch } from "react-icons/fa";
import { debounce } from "lodash";

function SnapshotClosedTasks(props) {
  const locationList = getLocationList();
  let {
    match: {
      params: { site_code },
    },
  } = props;
  const location = locationList.find((location) => location.location_code === site_code);

  if (!location) {
    props.history.replace("/404");
    return null;
  }

  const [
    isLoadingCategory,
    tasksCategory,
    currentPageCategory,
    setCurrentPageCategory,
    totalItemsCategory,
    setPropertySearchText,
  ] = useFetchSnapshotFacilityTasks(site_code, true, TASK_CATEGORIES[0].category);

  const [isLoadingUnit, tasksUnit, currentPageUnit, setCurrentPageUnit, totalItemsUnit, setUnitSearchText] =
    useFetchSnapshotFacilityTasks(site_code, true, TASK_CATEGORIES[1].category);

  function onSearch(text) {
    setUnitSearchText(text);
    setPropertySearchText(text);
  }

  const debouncedChangeTextSearch = debounce(onSearch, 500);

  const renderTasks = (category) => {
    const isLoading = category.category === "unit" ? isLoadingUnit : isLoadingCategory;
    const tasks = category.category === "unit" ? tasksUnit : tasksCategory;
    return (
      <div className="row col-12" key={category.category}>
        <h2 className="task-type-title">{"Closed: " + category.title}</h2>
        <div className="row col-12 d-flex flex-wrap mt-4 mb-4">
          {isLoading ? (
            <div className="text-center">
              <div className="spinner-border text-primary ml-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : null}
          {!isLoading && tasks.length
            ? tasks.map((task) => <Task key={task.id} task={task} category={category} site_code={site_code} />)
            : null}
          {!isLoading && !tasks.length ? <p className="ml-2">{"No task available"}</p> : null}
        </div>
      </div>
    );
  };

  const renderPagination = (category) => {
    if (category.category === "unit") {
      return (
        <div className="rol col-12 d-flex justify-content-center mt-4">
          <Pagination
            current={currentPageUnit}
            onChange={setCurrentPageUnit}
            total={totalItemsUnit}
            pageSize={PAGE_SIZE}
            locale={locale}
          />
        </div>
      );
    }
    return (
      <div className="rol col-12 d-flex justify-content-center mt-4">
        <Pagination
          current={currentPageCategory}
          onChange={setCurrentPageCategory}
          total={totalItemsCategory}
          pageSize={PAGE_SIZE}
          locale={locale}
        />
      </div>
    );
  };

  return (
    <div className="bg-light">
      <Helmet>
        <title>Snapshot</title>
      </Helmet>
      <SnapShotHeader />
      <div>
        <div className="row justify-content-between pt-2">
          <div className="col-9 ml-2">
            <BreadCrumb title="Facility Tasks" path={PATH.SNAPSHOT_FACILITY_TASKS + `/${site_code}`} />
          </div>
        </div>
      </div>
      <div className="col-10 mx-auto min-vh-100">
        <div className="row align-items-start justify-content-between">
          <div className="d-flex align-items-center">
            <p className={"title mr-2"}>{location.name}</p>
            <p className={"separator"}>|</p>
            <h1 className={"title-block m-0 mx-1"}>
              {titleComponents.map((comp) => location[comp.locationKey]).join(", ")}
            </h1>
          </div>
        </div>
        <div className="form-group position-relative search-input mt-4">
          <FaSearch className=" position-absolute" />
          <div>
            <input
              onChange={(e) => {
                const text = e.target.value;
                return debouncedChangeTextSearch(text);
              }}
              placeholder="Search by Unit Number or Task Type..."
            />
          </div>
        </div>
        <div className="row mt-4">
          {TASK_CATEGORIES.map((category) => (
            <>
              {renderTasks(category)}
              {renderPagination(category)}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SnapshotClosedTasks;
